import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserGetTokenDto } from '../../../shared/api/dto/request/models/user-get-token.dto';
import { Router } from '@angular/router';
import { MENU_PATH, SNACK_BAR_CLASS, SNACK_BAR_MSG } from '../../../shared/constants/vocabulary';
import { SnackBarService } from '../../main/services/snack-bar.service';
import { AuthService } from '../auth.service';
import { ApiPermissionService } from '../../../shared/api/services/api-permission.service';
import { ApiUserRespModel } from '../../../shared/api/dto/response/models/api-user-resp.model';
import { AuthUserService } from '../../../shared/api/services/auth-user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  public signInForm: FormGroup;
  private subscription: Subscription = new Subscription();
  private userData: UserGetTokenDto;
  public changeStatusFlag = false;
  public passwordHide = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService,
    private ps: ApiPermissionService,
    private authUserService: AuthUserService,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  public onLogin(): void {
    this.userData = new UserGetTokenDto(this.signInForm.value);
    this.logIn();
  }

  private logIn(): void {
    this.addSubscription(
      this.authService.logIn(this.userData)
        .subscribe((res: ApiUserRespModel) => {
            localStorage.setItem('token', res.token);
            this.authUserService.setUserInfo();
            this.router.navigate([`/${MENU_PATH.MAIN}`]);
          },
          error => {
            if (error.status === 307) {
              this.snackBarService.open(SNACK_BAR_MSG.CHANGE_PASSWORD, SNACK_BAR_CLASS.DONE);
              this.disabledForm();
              this.changeStatusFlag = true;
            }
          }),
    );
  }

  private disabledForm(): void {
    this.signInForm.disable();
  }

  private enableForm(): void {
    this.signInForm.enable();
  }

  public newPassword(): void {
    this.changeStatusFlag = false;
    this.signInForm.controls.password.reset();
    this.enableForm();
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
