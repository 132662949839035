import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IRefreshPassword } from '../../../shared/api/dto/request/interfaces/refresh-password.interface';
import { RefreshPasswordDto } from '../../../shared/api/dto/request/models/refresh-password.dto';
import { AuthService } from '../auth.service';
import { SnackBarService } from '../../main/services/snack-bar.service';
import { SNACK_BAR_CLASS, SNACK_BAR_MSG } from '../../../shared/constants/vocabulary';

@Component({
  selector: 'app-refresh-password',
  templateUrl: './refresh-password.component.html',
  styleUrls: ['./refresh-password.component.scss'],
})
export class RefreshPasswordComponent implements OnInit, OnDestroy {
  @Input() email: string;
  @Input() password: string;
  @Output() newPassword = new EventEmitter();
  public changePasswordForm: FormGroup;
  private subscription: Subscription = new Subscription();
  private refreshPassword: IRefreshPassword;
  public passwordHide = true;
  public confirmPasswordHide = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sb: SnackBarService,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(5)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  public confirmPassword(): void {
    if (this.changePasswordForm.controls.newPassword.value === this.changePasswordForm.controls.confirmNewPassword.value) {
      this.refreshPassword = {
        email: this.email,
        password: this.password,
        newPassword: this.changePasswordForm.controls.newPassword.value,
      };
      this.setNewPassword(this.refreshPassword);
    }
  }

  private setNewPassword(refreshPasswordData: IRefreshPassword): void {
    const data = new RefreshPasswordDto(refreshPasswordData);
    this.addSubscription(
      this.authService.refreshPassword(data)
        .subscribe((res: any) => {
          if (res.success) {
            this.sb.open(SNACK_BAR_MSG.CHANGE_PASSWORD_DONE, SNACK_BAR_CLASS.DONE);
            this.newPassword.emit();
          }
        }),
    );
  }

  public checkPassword(): boolean {
    const newPassword = this.changePasswordForm.controls.newPassword.value;
    const confirmNewPassword = this.changePasswordForm.controls.confirmNewPassword.value;
    return newPassword !== confirmNewPassword;
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
