<div class="form-wrapper">
  <h2>Welcome to Royal Reels  ECO</h2>
  <form [formGroup]="signInForm">
    <div class="container">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Enter Email</mat-label>
            <input matInput formControlName="email" type="email">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Enter Password</mat-label>
            <input matInput formControlName="password" [type]="passwordHide ? 'password' : 'text'">
            <mat-icon class="eye" matSuffix (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary btn-block"
                  [disabled]="signInForm.invalid || changeStatusFlag"
                  (click)="onLogin()">
            Log In
          </button>
        </div>
      </div>
    </div>
  </form>
  <app-refresh-password
    *ngIf="changeStatusFlag"
    (newPassword)="newPassword()"
    [email]="signInForm.controls.email.value"
    [password]="signInForm.controls.password.value"
  ></app-refresh-password>
</div>
