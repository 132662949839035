import { environment } from '../../../environments/environment';

const api = environment.host;

export const API_URLS = {
  auth: {
    login: api + '/auth/login',
    refreshPassword: api + '/auth/refresh-password',
  },
  games: {
    url: api + '/games',
    count: api + '/games/count',
    getById: (id: number) => `${API_URLS.games.url}/${id}`,
    mathConfig: api + '/games/math-configs',
  },
  availableGames: {
    url: api + '/available-games',
    count: api + '/available-games/count',
  },
  operators: {
    url: api + '/operators',
    count: api + '/operators/count',
    getById: (id: number) => `${API_URLS.operators.url}/${id}`,
    update: (id: number) => `${API_URLS.operators.url}/${id}`,
    bulkUpdate: api + '/operators',
  },
  operatorsShortList: {
    url: api + '/operators/short/list',
    getById: (id: number) => `${API_URLS.operatorsShortList.url}/${id}`,
    update: (id: number) => `${API_URLS.operatorsShortList.url}/${id}`,
    bulkUpdate: api + '/operators/short/list',
  },
  brands: {
    url: api + '/brands',
    count: api + '/brands/count',
    getById: (id: number) => `${API_URLS.brands.url}/${id}`,
    update: (id: number) => `${API_URLS.brands.url}/${id}`,
    bulkUpdate: api + '/brands',
    gamesPerBrand: api + '/brands/games',
    gamesPerBrandCount: api + '/brands/games/count',
    updateGameConfigOfBrand: (id: number) => api + `/brands/${id}/game-config`,
  },
  players: {
    playersUrl: api + '/players',
    getById: (id: number) => `${API_URLS.players.playersUrl}/${id}`,
    postTestPlayers: api + '/players/testers',
  },
  currency: {
    url: api + '/currencies',
    updateById: (id: number) => `${API_URLS.currency.url}/${id}`,
  },
  users: {
    url: api + '/users',
    getById: (id: number) => `${API_URLS.users.url}/${id}`,
    putById: (id: number) => `${API_URLS.users.url}/${id}`,
  },
  roles: {
    rolesList: api + '/roles',
    role: api + '/role',
    permissions: api + '/permissions/user',
  },
  immutable: {
    platform: api + '/immutable/platforms',
    languages: api + '/immutable/languages',
    countries: api + '/immutable/countries',
    skins: api + '/immutable/skins',
  },
  userActivity: {
    url: api + '/user-activity',
    count: api + '/user-activity/count',
  },
  historyLink: {
    url: api + '/reports',
  },
  marketingMaterial: {
    url: api + '/marketing',
    postMaterial: (name: string) => api + `/marketing/${name}`,
    deleteMaterial: (name: string) => api + `/marketing/${name}`,
  },
  secretToken: {
    refresh: (id: string) => api + `/operators/token/${id}`,
  },
  analytics: {
    game: api + '/reports/operator-brand-game',
    operator: api + '/reports/operator-brand',
    month: api + '/reports/operator-monthly',
  },
  jurisdictions: {
    url: api + '/jurisdictions',
  },
  nft: {
    url: api + '/nft',
    create: api + '/nft',
    count: api + '/nft/count',
    delete: (id: number) => `${API_URLS.nft.url}/${id}`,
    update: (id: number) => `${API_URLS.nft.url}/${id}`,
  }
};
