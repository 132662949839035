import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Injectable()
export class ApiPermissionService {
  constructor(
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
  ) {
  }

  public setRoles(): void {
    this.rolesService.addRoles({
      SUPER_ADMIN: [
        'HAS_ACCESS_TO_MODULE__USERS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_USERS',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_NEW_USER',
        'HAS_ACCESS_TO_ENDPOINT__MODIFY_EXISTED_USER',
        'HAS_ACCESS_TO_ENDPOINT__REMOVE_USER',
        'HAS_ACCESS_TO_ENDPOINT__GET_USER_DETAILS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ROLES',
        'HAS_ACCESS_TO_ENDPOINT__GET_ROLE_BY_ID',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_ROLE',
        'HAS_ACCESS_TO_ENDPOINT__DELETE_ROLE',
        'HAS_ACCESS_TO_ENDPOINT__GET_PERMISSIONS_FOR_ROLE',
        'HAS_ACCESS_TO_ENDPOINT__SET_PERMISSIONS_FOR_ROLE',
        'HAS_ACCESS_TO_ENDPOINT__SET_ROLE_TO_USER',
        'HAS_ACCESS_TO_ENDPOINT__UNSET_ROLE_TO_USER',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_PERMISSIONS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_MODULES',
        'HAS_ACCESS_TO_ENDPOINT__GET_MODULE_BY_ID',
        'HAS_ACCESS_TO_ENDPOINT__GET_MODULE_PERMISSIONS',
        'HAS_ACCESS_TO_MODULE__RBAC',
        'HAS_ACCESS_TO_MODULE__OPERATORS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_OPERATORS',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_NEW_OPERATOR',
        'HAS_ACCESS_TO_ENDPOINT__MODIFY_EXISTED_OPERATOR',
        'HAS_ACCESS_TO_ENDPOINT__REMOVE_OPERATOR',
        'HAS_ACCESS_TO_MODULE__BRAND',
        'HAS_ACCESS_TO_ENDPOINT__UPDATE_BRAND_GAME_CONFIG',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_BRAND_GAME_CONFIG',
        'HAS_ACCESS_TO_MODULE__PLAYERS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_PLAYERS',
        'HAS_ACCESS_TO_ENDPOINT__DISABLE_PLAYER',
        'HAS_ACCESS_TO_ENDPOINT__LOGIN_HISTORY',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_BRANDS',
        'HAS_ACCESS_TO_ENDPOINT__MODIFY_EXISTED_BRAND',
        'HAS_ACCESS_TO_ENDPOINT__MODIFY_EXISTED_NFT',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_NFT',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_NFTS',
        'HAS_ACCESS_TO_MODULE__NFT',
      ],
      OPERATOR_VIEWER: [
        'HAS_ACCESS_TO_MODULE__SPINS',
        'HAS_ACCESS_TO_ENDPOINT__GET_SPIN',
        'HAS_ACCESS_TO_MODULE__REPORTS',
        'HAS_ACCESS_TO_ENDPOINT__GENERATE_REPORT_LINK',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_PLAYERS',
        'HAS_ACCESS_TO_MODULE__PLAYERS',
        'HAS_ACCESS_TO_ENDPOINT__LOGIN_HISTORY',
        'HAS_ACCESS_TO_MODULE__OPERATORS',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_OPERATORS',
        'HAS_ACCESS_TO_MODULE__GAMES',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_GAMES',
        'HAS_ACCESS_TO_ENDPOINT__GET_BY_ID',
        'HAS_ACCESS_TO_ENDPOINT__GET_GAMES_COUNT',
        'HAS_ACCESS_TO_MODULE__CURRENCIES',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_CURRENCIES',
        'HAS_ACCESS_TO_MODULE__BRAND',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_BRANDS',
        'HAS_ACCESS_TO_MODULE__AVAILABLE_GAMES',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_AVAILABLE_GAMES',
        'HAS_ACCESS_TO_MODULE__MARKETING',
        'HAS_ACCESS_TO_ENDPOINT__GET_MARKETING_PACKS_LIST',
        'HAS_ACCESS_TO_MODULE__HISTORY',
        'HAS_ACCESS_TO_ENDPOINT__GET_USER_HISTORY',
        'HAS_ACCESS_TO_ENDPOINT__GET_USER_HISTORY_COUNT',
        'HAS_ACCESS_TO_ENDPOINT__MODIFY_EXISTED_NFT',
        'HAS_ACCESS_TO_ENDPOINT__CREATE_NFT',
        'HAS_ACCESS_TO_ENDPOINT__UPDATE_BRAND_GAME_CONFIG',
        'HAS_ACCESS_TO_ENDPOINT__GET_ALL_NFTS',
        'HAS_ACCESS_TO_MODULE__NFT',
      ],
    });
  }

  public removePermissions(): void {
    this.permissionsService.flushPermissions();
  }

  public setPermissions(respPermission: string[]): void {
    this.permissionsService.loadPermissions(respPermission);
  }

  public getRoles(): void {
    this.rolesService.roles$.subscribe((data) => {
    });
  }

  public getPermissions(): void {
    this.permissionsService.permissions$.subscribe((permissions) => {
    });
  }
}
