import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MENU_PATH } from './shared/constants/vocabulary';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { AuthGuard } from './shared/api/guards/auth.guard';
import { CheckTokenGuard } from './shared/api/guards/check-token.guard';

const routes: Routes = [
  { path: MENU_PATH.LOGIN, component: SignInComponent, canActivate: [AuthGuard] },
  { path: MENU_PATH.MAIN, loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), canActivate: [CheckTokenGuard] },
  { path: '', redirectTo: MENU_PATH.LOGIN, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
